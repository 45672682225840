import DialogActions from '@material-ui/core/DialogActions';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

import {ModalContext} from 'components/modals/modal-context';
import {ButtonContext} from 'hooks/button-context';

import * as ModalConstants from 'components/modals/modal-constants';

const useStyles = makeStyles((theme) => ({
    rootSmall: {
        padding: ModalConstants.modalMarginSm,
    },
    rootMedium: {
        padding: ModalConstants.modalMarginMd,
    },
    rootLarge: {
        padding: ModalConstants.modalMarginLg,
    },
}));

const ModalActions = ({children}) => {
    const classes = useStyles();
    const {size, open} = useContext(ModalContext);
    const buttonSize = size === 'small' ? 'small' : 'medium';

    let root;
    switch (size) {
        case 'small':
            root = classes.rootSmall;
            break;
        case 'large':
            root = classes.rootLarge;
            break;
        case 'medium':
        default:
            root = classes.rootMedium;
            break;
    }

    const buttonContextValue = {
        size: buttonSize,
        disabled: !open,
    };

    return (
        <ButtonContext.Provider value={buttonContextValue}>
            <DialogActions classes={{root}}>{children}</DialogActions>
        </ButtonContext.Provider>
    );
};

ModalActions.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ModalActions;
