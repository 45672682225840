import {datadogRum} from '@datadog/browser-rum';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect} from 'react';

import SCButton from 'components/sc-button';

import type {SCButtonProps} from 'components/sc-button';

const useStyles = makeStyles((theme) => ({
    podLayout: {
        position: 'relative',
        margin: '8rem auto 0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    podContent: {},
    line1: {
        ...theme.typography.componentM,
        paddingTop: '3.2rem',
        paddingBottom: '1.6rem',
    },
    line2: {
        ...theme.typography.componentM,
        paddingBottom: '3.2rem',
    },
}));

type RumLabel = 'load_failed' | 'not_found';

type Props = {
    rumLabel: RumLabel;
    line1: string;
    line2: string;
    imageSource: string;
    buttonLabel: string;
    buttonOnClick: SCButtonProps['onClick'];
    error?: unknown;
};

const getSerializedError = (error) => {
    try {
        return JSON.stringify(error, Object.getOwnPropertyNames(error));
    } catch (err) {
        return 'No error available.';
    }
};

const PageError = (props: Props) => {
    const {rumLabel, line1, line2, imageSource, buttonLabel, buttonOnClick, error} = props;

    const classes = useStyles();
    useEffect(() => {
        datadogRum.addAction(`${rumLabel}_mount`, {
            error: getSerializedError(error),
        });

        return () => {
            datadogRum.addAction(`${rumLabel}_unmount`);
        };
    }, [rumLabel, error]);

    const handleClick = (event) => {
        datadogRum.addAction(`${rumLabel}_click`);
        buttonOnClick(event);
    };

    return (
        <div className={classes.podLayout}>
            <img src={imageSource} alt={imageSource} />
            <Typography className={classes.line1}>{line1}</Typography>
            <Typography className={classes.line2}>{line2}</Typography>
            <SCButton
                variant='secondary'
                disabled={false}
                inProgress={false}
                onClick={handleClick}
                label={buttonLabel}
            />
        </div>
    );
};

export default PageError;
