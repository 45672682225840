import {StylesProvider, ThemeProvider, createTheme, makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import {useFlags} from 'launchdarkly-react-client-sdk';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, {Suspense, lazy, useEffect, useMemo, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import {fetchLookups} from 'actions/session-actions';

import {AccessibilityContext} from 'contexts/accessibility-context';

import {anonymousRoutePaths} from 'views/anonymous/anonymous-routes';

const AnonymousRoutes = lazy(() => import(/* webpackChunkName: "anonymous" */ 'views/anonymous/anonymous-routes'));
const SignedInRoutes = lazy(() => import(/* webpackChunkName: "signed-in" */ 'views/signed-in/signed-in-routes'));
const CenterSignInKioskRoutes = lazy(
    () => import(/* webpackChunkName: "center-sign-in-kiosk" */ 'views/csi-kiosk/csi-kiosk-routes'),
);
const CometRoutes = lazy(() => import(/* webpackChunkName: "comet" */ 'views/comet/comet-routes'));
const ClusterRoutes = lazy(() => import(/* webpackChunkName: "cluster" */ 'views/cluster/cluster-routes'));
const KitchenSinkRoutes = lazy(
    () => import(/* webpackChunkName: "kitchen-sink" */ 'views/kitchen-sink/kitchen-sink-routes'),
);
const AuthorizedAnonymousRoutes = lazy(
    () =>
        import(/* webpackChunkName: "authorized-anonymous" */ 'views/authorized-anonymous/authorized-anonymous-routes'),
);

import useIntlMessages from 'hooks/use-intl-messages';

import {createAxiosRetryInterceptor} from 'utils/api-utils';
import * as AppcuesUtils from 'utils/appcues-utils';
import * as HeapIoUtils from 'utils/heap-io-utils';
import Logger from 'utils/logger';
import MeteorCookies from 'utils/meteor-cookies';

import {
    ACCESSIBILITY_DEFAULT_VALUES,
    ACCESSIBILITY_LOCAL_STORAGE_KEY,
    MOTION_OPTIONS,
    THEME_OPTIONS,
} from 'views/signed-in/accessibility/accessibility-constants';

// This import is required for global styling
// eslint-disable-next-line
import S from './app-routes.less';

import componentProps from 'mui/component-props';
import mixins from 'mui/mixins/mixins';
import getOverrides from 'mui/overrides';
import {PaletteDark, PaletteLight} from 'mui/palette';
import shadows from 'mui/shadows';
import {transitionsNone, transitionsNormal, transitionsReduced} from 'mui/transitions';
import getTypography from 'mui/typography';

const showComponents = __SHOW_COMPONENTS__;

const logger = new Logger('app-component');
const BASE_RETRY_DELAY = 100;
const MAX_NETWORK_ERROR_RETRIES = 2;

const AppRoutes = React.memo(() => {
    const dispatch = useDispatch();
    // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
    const client = useSelector((state) => state.session.client);
    // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
    const lookups = useSelector((state) => state.session.lookups);
    // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
    const locale = useSelector((state) => state.session.settings.get('locale'));
    // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
    const loggedInUser = useSelector((state) => state.session.user);

    // @ts-expect-error TS(2339) FIXME: Property 'intl' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    const formats = useSelector((state) => state.intl.formats);
    // @ts-expect-error TS(2339) FIXME: Property 'intl' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    const messages = useSelector((state) => state.intl.messages);

    // Use JS object so values can be compared in useMemo
    const [currentAccessibilitySettings, setCurrentAccessibilitySettings] = useState(() => {
        // If no value is found, set to defaults
        if (isNil(localStorage.getItem(ACCESSIBILITY_LOCAL_STORAGE_KEY))) {
            localStorage.setItem(ACCESSIBILITY_LOCAL_STORAGE_KEY, JSON.stringify(ACCESSIBILITY_DEFAULT_VALUES));
        }
        // Parse JSON string back to JSON object
        const currentValues = JSON.parse(localStorage.getItem(ACCESSIBILITY_LOCAL_STORAGE_KEY));
        // Overwrite default values so that any new settings are defaulted and existing settings are kept
        return Object.assign({}, ACCESSIBILITY_DEFAULT_VALUES, currentValues);
    });

    const identifyUser = () => {
        AppcuesUtils.identifyUser(window, logger, {
            client,
            user: loggedInUser,
            language: locale,
            version: __VERSION__,
        });
        HeapIoUtils.identifyUser(window, logger, {
            userId: loggedInUser.get('userId'),
            firstName: loggedInUser.get('firstName'),
            lastName: loggedInUser.get('lastName'),
            email: loggedInUser.get('email'),
            userName: loggedInUser.get('userName'),
        });
    };

    const location = useLocation();
    const {
        globalAxiosRequestRetries,
        globalRequestRetriesDelayTime,
        globalRequestRetriesRetryLimit,
        enableAccessibilitySettingThemeMode,
    } = useFlags();

    const isLookupsLoaded = !isNil(lookups);
    const isMessagesLoaded = useIntlMessages('global');
    const isLoaded = isLookupsLoaded && isMessagesLoaded;

    useEffect(() => {
        if (globalAxiosRequestRetries) {
            const delayTime = globalRequestRetriesDelayTime || BASE_RETRY_DELAY;
            const retryLimit = globalRequestRetriesRetryLimit || MAX_NETWORK_ERROR_RETRIES;
            axios.interceptors.response.use(...createAxiosRetryInterceptor(0, delayTime, retryLimit));
        }
    }, []);

    useEffect(() => {
        identifyUser();
        AppcuesUtils.trackPageChange(window, logger);
    }, [location.pathname]);

    useEffect(() => {
        if (!isLookupsLoaded) {
            const failureCallback = (err) => {
                console.error('Failed to fetch lookups:', err);
            };
            dispatch(fetchLookups(noop, failureCallback));
        }
    }, [isLookupsLoaded]);

    const cookies = MeteorCookies.getInstance();

    const isAnonymous = isNil(cookies.get('token'));

    const handleSetAccessibilitySettings = (updatedAccessibilitySettings) => {
        // Maintain compatibility with values that do not contain all settings
        const nextValues = Object.assign(currentAccessibilitySettings, updatedAccessibilitySettings);
        // Spread values to trigger state update and a rerender
        setCurrentAccessibilitySettings({...nextValues});
        // Save to locale storage
        localStorage.setItem(ACCESSIBILITY_LOCAL_STORAGE_KEY, JSON.stringify(nextValues));
    };

    const paletteOptions = useMemo(() => {
        if (!enableAccessibilitySettingThemeMode) {
            return PaletteLight;
        }

        switch (currentAccessibilitySettings.theme) {
            case THEME_OPTIONS.DARK:
                return PaletteDark;
            case THEME_OPTIONS.LIGHT:
            default:
                return PaletteLight;
        }
    }, [currentAccessibilitySettings.theme, enableAccessibilitySettingThemeMode]);

    const typographyOptions = useMemo(() => {
        return getTypography(paletteOptions);
    }, [paletteOptions]);

    const transitions = useMemo(() => {
        switch (currentAccessibilitySettings.motion) {
            case MOTION_OPTIONS.NONE:
                return transitionsNone;
            case MOTION_OPTIONS.REDUCED:
                return transitionsReduced;
            case MOTION_OPTIONS.NORMAL:
            default:
                return transitionsNormal;
        }
    }, [currentAccessibilitySettings.motion]);

    const overrides = useMemo(() => {
        return getOverrides(typographyOptions);
    }, [typographyOptions]);

    const theme = useMemo(() => {
        return createTheme({
            mixins,
            overrides,
            palette: paletteOptions,
            props: componentProps,
            shadows,
            spacing: (factor) => `${10 * factor}px`, // spacing in multiples of 10px
            transitions,
            typography: typographyOptions,
        });
    }, [overrides, paletteOptions, transitions, typographyOptions]);

    return (
        <IntlProvider locale={locale} messages={messages} formats={formats} textComponent='span'>
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <AccessibilityContext.Provider
                        value={{
                            accessibilitySettings: currentAccessibilitySettings,
                            setAccessibilitySettings: handleSetAccessibilitySettings,
                        }}>
                        <AppRoutesContent isLoaded={isLoaded} isAnonymous={isAnonymous} />
                    </AccessibilityContext.Provider>
                </ThemeProvider>
            </StylesProvider>
        </IntlProvider>
    );
});

const useStyles = makeStyles((theme) => ({
    app: {
        ...theme.mixins.legacyTypography.mainFont,
        background:
            theme.palette.type === 'light' ? theme.mixins.legacyColors.backgroundLightGray.background : '#202125',
    },
    viewLayer: theme.mixins.legacyLayout.isolate,
}));

const AppRoutesContent = (props) => {
    const {isLoaded, isAnonymous} = props;
    const classes = useStyles();
    const location = useLocation();

    return (
        <div className={classes.app}>
            <div className={classes.viewLayer}>
                {isLoaded && (
                    <Switch>
                        {showComponents && (
                            <Route path='/components/'>
                                <Suspense fallback={null}>
                                    <KitchenSinkRoutes />
                                </Suspense>
                            </Route>
                        )}
                        <Route path='/center-sign-in/kiosk/'>
                            <Suspense fallback={null}>
                                <CenterSignInKioskRoutes />
                            </Suspense>
                        </Route>
                        <Route path='/exams'>
                            <Suspense fallback={null}>
                                <CometRoutes />
                            </Suspense>
                        </Route>
                        <Route path='/cluster'>
                            <Suspense fallback={null}>
                                <ClusterRoutes />
                            </Suspense>
                        </Route>
                        <Route path='/session-viewer'>
                            <Suspense fallback={null}>
                                <AuthorizedAnonymousRoutes />
                            </Suspense>
                        </Route>
                        <Route path={anonymousRoutePaths} exact>
                            <Suspense fallback={null}>
                                <AnonymousRoutes />
                            </Suspense>
                        </Route>
                        {isAnonymous && (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: {nextLocation: location},
                                }}
                            />
                        )}
                        {!isAnonymous && (
                            <Suspense fallback={null}>
                                <SignedInRoutes />
                            </Suspense>
                        )}
                    </Switch>
                )}
            </div>
        </div>
    );
};

AppRoutesContent.propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
};

export default AppRoutes;
