import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import {routerActions} from 'actions/router-actions';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {requestResetPassword} from 'actions/session-actions';
import {setStateSafe} from 'utils/component-utils';
import FormUtils from 'utils/form-utils';

import InputField from 'components/forms/components/input-field';
import formManager from 'components/forms/form-manager';
import SCButton from 'components/sc-button';

import messages from 'intl/anonymous-messages';
import globalMessages from 'intl/global-messages';

import S from './request-reset-password.less';

const {any, func} = PropTypes;

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            requestResetPassword,
            ...routerActions,
        },
        dispatch,
    );
}

// @ts-expect-error TS(1270) FIXME: Decorator function return type 'FC<WithIntlProps<a... Remove this comment to see the full error message
@injectIntl
// @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
@formManager()
// @ts-expect-error TS(1270) FIXME: Decorator function return type 'ConnectedComponent... Remove this comment to see the full error message
@connect(
    (state) => ({
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        client: state.session.client,
        // @ts-expect-error TS(2339) FIXME: Property 'session' does not exist on type 'Default... Remove this comment to see the full error message
        settings: state.session.settings,
    }),
    mapDispatchToProps,
    null,
    {forwardRef: true},
)
export default class RequestResetPassword extends React.Component {
    static propTypes = {
        requestResetPassword: func,
        handleSubmit: func,
        push: func,
        intl: any,
        settings: any,
        client: any,
    };

    static defaultProps = {};

    constructor(props, context) {
        super(props, context);
        this.state = {
            emailSent: false,
            inProgress: false,
        };
        this.setStateSafe = setStateSafe.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleRequestResetPassword(data) {
        const identifier = data.get('resetIdentifier');
        this.setState({
            inProgress: true,
        });
        this.props.requestResetPassword(
            identifier,
            this.onRequestResetPasswordSuccess.bind(this),
            this.onRequestResetPasswordFailure.bind(this),
        );
    }

    onRequestResetPasswordSuccess(response) {
        this.setStateSafe({
            emailSent: true,
            inProgress: false,
        });
    }

    onRequestResetPasswordFailure(errorMessage) {
        this.setStateSafe({
            errorMessage: messages.resetError,
            inProgress: false,
        });
    }

    returnToLogin() {
        this.props.push('/');
    }

    render() {
        const {emailSent, errorMessage, inProgress} = this.state;
        const {intl} = this.props;
        const {formatMessage} = intl;

        const onSubmit = this.props.handleSubmit(this.handleRequestResetPassword.bind(this));

        let requestResetPasswordDescription = messages.requestResetPasswordDescriptionUniqueDomain;
        let resetPasswordIdentifierLabel = messages.resetPasswordIdentifierLabelUniqueDomain;
        if (!this.props.settings.get('uniqueDomain', false)) {
            requestResetPasswordDescription = messages.requestResetPasswordDescription;
            resetPasswordIdentifierLabel = messages.resetPasswordIdentifierLabel;
        }

        return (
            <div className={S.main}>
                <form className={S.form} noValidate onSubmit={FormUtils.preventSubmit}>
                    {emailSent ? (
                        <div className={S.emailSent}>
                            <div className={S.resetDescription}>
                                <Typography gutterBottom>
                                    <FormattedMessage {...messages.resetPasswordSentDescription} />
                                </Typography>
                            </div>
                            <SCButton
                                variant='secondary'
                                disabled={false}
                                inProgress={false}
                                onClick={this.returnToLogin.bind(this)}
                                startIcon={<ArrowBackIcon />}
                                label={formatMessage(globalMessages.back)}
                            />
                        </div>
                    ) : (
                        <div>
                            <div className={S.resetDescription}>
                                <label className={S.label}>
                                    <FormattedMessage {...messages.requestResetPasswordTitle} />
                                </label>
                                <p>
                                    <FormattedMessage {...requestResetPasswordDescription} />
                                </p>
                            </div>
                            <div>
                                <label htmlFor='reset_email' className={S.label}>
                                    <FormattedMessage {...resetPasswordIdentifierLabel} />
                                </label>
                                <InputField
                                    className={S.input}
                                    ref={(c) => {
                                        this.resetPasswordIdentifier = c;
                                    }}
                                    autoCapitalize='off'
                                    name='resetIdentifier'
                                    required={true}
                                    onEnter={onSubmit}
                                />
                            </div>
                            {isNil(errorMessage) ? null : (
                                <div className={S.errorMessage}>
                                    <p className={S.errorText}>
                                        <FormattedMessage {...errorMessage} />
                                    </p>
                                </div>
                            )}
                            <div className={S.controlsContainer}>
                                <SCButton
                                    variant='secondary'
                                    disabled={false}
                                    inProgress={false}
                                    onClick={this.returnToLogin.bind(this)}
                                    startIcon={<ArrowBackIcon />}
                                    label={formatMessage(globalMessages.back)}
                                />
                                <SCButton
                                    variant='primary'
                                    disabled={false}
                                    inProgress={inProgress}
                                    onClick={onSubmit}
                                    startIcon={<EmailIcon />}
                                    label={formatMessage(messages.send)}
                                />
                            </div>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}
