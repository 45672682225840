import * as SCTheme from 'constants/sc-theme';
import {PaletteLight} from 'mui/palette';
import shadows from 'mui/shadows';

import type {TypographyOptions} from '@material-ui/core/styles/createTypography';
import type {Overrides} from '@material-ui/core/styles/overrides';

const inputBorderRadius = '0.4rem';
const inputHeight = '3.6rem';
const listItemHeight = '3rem';

const buttonOverrides = {
    containedPrimary: {
        color: PaletteLight.common.white,
        backgroundColor: PaletteLight.primary['500'],
        '&:active': {
            backgroundColor: PaletteLight.primary['700'],
        },
        '&:disabled': {
            color: PaletteLight.grey['400'],
            backgroundColor: PaletteLight.grey['100'],
        },
        '&:focus:not(:active)': {
            backgroundColor: PaletteLight.primary['400'],
        },
        '&:hover': {
            backgroundColor: PaletteLight.primary['400'],
        },
    },
    containedSecondary: {
        color: PaletteLight.common.white,
        backgroundColor: PaletteLight.error['500'],
        '&:active': {
            backgroundColor: PaletteLight.error['800'],
        },
        '&:disabled': {
            color: PaletteLight.grey['400'],
            backgroundColor: PaletteLight.grey['100'],
        },
        '&:focus:not(:active)': {
            backgroundColor: PaletteLight.error['700'],
        },
        '&:hover': {
            backgroundColor: PaletteLight.error['700'],
        },
    },
    outlinedPrimary: {
        color: PaletteLight.primary['500'],
        borderColor: PaletteLight.primary['500'],
        backgroundColor: PaletteLight.common.white,
        '&:active': {
            color: PaletteLight.primary['700'],
            borderColor: PaletteLight.primary['700'],
            backgroundColor: PaletteLight.common.white,
        },
        '&:focus:not(:active)': {
            color: PaletteLight.primary['400'],
            borderColor: PaletteLight.primary['400'],
            backgroundColor: PaletteLight.common.white,
        },
        '&:hover': {
            color: PaletteLight.primary['400'],
            borderColor: PaletteLight.primary['400'],
            backgroundColor: PaletteLight.common.white,
        },
    },
    outlinedSecondary: {
        color: PaletteLight.error['500'],
        borderColor: PaletteLight.error['500'],
        backgroundColor: PaletteLight.common.white,
        '&:active': {
            color: PaletteLight.error['700'],
            borderColor: PaletteLight.error['700'],
            backgroundColor: PaletteLight.common.white,
        },
        '&:hover': {
            color: PaletteLight.error['400'],
            borderColor: PaletteLight.error['400'],
            backgroundColor: PaletteLight.common.white,
        },
        '&:focus:not(:active)': {
            color: PaletteLight.error['400'],
            borderColor: PaletteLight.error['400'],
            backgroundColor: PaletteLight.common.white,
        },
    },
};

const getOverrides = (typographyOptions: TypographyOptions): Overrides => ({
    MuiBackdrop: {
        root: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
    },
    MuiButtonGroup: {
        contained: {
            boxShadow: 'none',
        },
        groupedContainedPrimary: buttonOverrides.containedPrimary,
        groupedContainedSecondary: buttonOverrides.containedSecondary,
        groupedOutlined: {
            backgroundColor: PaletteLight.common.white,
            color: PaletteLight.primary['500'],
            borderColor: PaletteLight.primary['500'],
            '&:hover': {
                color: PaletteLight.primary['400'],
                borderColor: PaletteLight.primary['400'],
                backgroundColor: PaletteLight.common.white,
            },
            '&:disabled': {
                color: PaletteLight.grey['400'],
                borderColor: PaletteLight.grey['100'],
            },
        },
        groupedOutlinedPrimary: buttonOverrides.outlinedPrimary,
        groupedOutlinedSecondary: buttonOverrides.outlinedSecondary,
    },
    MuiButton: {
        sizeSmall: {
            height: SCTheme.SIZE_SMALL_HEIGHT,
            fontSize: typographyOptions.componentS.fontSize,
            lineHeight: typographyOptions.componentS.lineHeight,
            borderRadius: '0.4rem',
        },
        sizeLarge: {
            height: SCTheme.SIZE_LARGE_HEIGHT,
            fontSize: typographyOptions.componentM.fontSize,
            lineHeight: typographyOptions.componentM.lineHeight,
            fontWeight: typographyOptions.fontWeightBold as number,
            borderRadius: '0.8rem',
        },
        root: {
            height: SCTheme.SIZE_MEDIUM_HEIGHT,
            minWidth: '6.4rem',
            width: 'fit-content',
            fontSize: typographyOptions.componentM.fontSize,
            lineHeight: typographyOptions.componentM.lineHeight,
            fontWeight: typographyOptions.fontWeightBold as number,
            whiteSpace: 'nowrap',
            borderRadius: '0.6rem',
            // Default transition with 'color' added to match 'background-color'
            transition:
                'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&$disabled': {
                color: PaletteLight.grey['400'],
                borderColor: PaletteLight.grey['100'],
            },
        },
        // text buttons are styled like links
        text: {
            border: 'none',
            color: PaletteLight.primary['500'],
            '&:hover': {
                background: 'none',
                textDecoration: 'underline',
            },
        },
        textPrimary: {
            color: PaletteLight.primary['500'],
            '&:active': {
                backgroundColor: PaletteLight.primary['100'],
                color: PaletteLight.primary['700'],
            },
            '&:focus:not(:active)': {
                backgroundColor: PaletteLight.primary['20'],
            },
            '&:hover': {
                backgroundColor: PaletteLight.primary['20'],
                textDecoration: 'none',
            },
        },
        textSecondary: {
            color: PaletteLight.error['500'],
            '&:active': {
                backgroundColor: PaletteLight.error['100'],
                color: PaletteLight.error['700'],
            },
            '&:focus:not(:active)': {
                backgroundColor: PaletteLight.error['20'],
            },
            '&:hover': {
                backgroundColor: PaletteLight.error['20'],
                textDecoration: 'none',
            },
        },
        ...buttonOverrides,
        textSizeSmall: typographyOptions.componentXs,
    },
    MuiInputBase: {
        input: {
            color: PaletteLight.text.secondary,
            '&::placeholder': {
                ...typographyOptions.componentItalicS,
                color: PaletteLight.text.hint,
                opacity: 1,
                transition: 'none',
            },
        },
    },
    MuiInputLabel: {
        root: {
            ...typographyOptions.componentUppercaseXs,
            paddingBottom: 8,
        },
    },
    MuiMenu: {
        list: {
            paddingTop: 5,
            paddingBottom: 5,
            boxShadow: shadows[1],
        },
    },
    MuiMenuItem: {
        root: {
            lineHeight: typographyOptions.componentS.lineHeight,
            height: listItemHeight,
            '&:hover': {
                backgroundColor: PaletteLight.grey['100'],
            },
        },
    },
    MuiSelect: {
        root: {
            borderRadius: inputBorderRadius,
            cursor: 'pointer',
            height: inputHeight,
        },
    },
    MuiSvgIcon: {
        colorAction: {
            color: PaletteLight.warning['400'],
        },
        root: {
            fontSize: '2.4rem',
        },
    },
    MuiTableHead: {
        root: {
            backgroundColor: PaletteLight.grey['100'],
        },
    },
    MuiTableBody: {
        root: {
            '& > tr:nth-child(odd)': {
                backgroundColor: PaletteLight.grey['20'],
            },
        },
    },
    MuiTooltip: {
        tooltip: {
            fontSize: typographyOptions.componentXs.fontSize,
            lineHeight: typographyOptions.componentXs.lineHeight,
            color: PaletteLight.text.primary,
            backgroundColor: PaletteLight.common.white,
            borderRadius: '0.5rem',
            padding: '1rem',
            boxShadow: shadows[1],
            borderColor: PaletteLight.grey['100'],
            borderStyle: 'solid',
            borderWidth: '0.2rem',
            maxWidth: '28rem',
            whiteSpace: 'break-spaces',
        },
        arrow: {
            color: PaletteLight.common.white,
            fontSize: typographyOptions.componentXl.fontSize,

            '&:before': {
                boxShadow: shadows[1],
                borderColor: PaletteLight.grey['100'],
                borderStyle: 'solid',
                borderWidth: '0.2rem',
                boxSizing: 'border-box',
            },
        },
    },
    MuiTypography: {
        colorError: {
            color: PaletteLight.error['500'],
        },
    },
});

export default getOverrides;
