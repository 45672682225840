import {ACTIVITY_LIMIT} from 'constants/activity-constants';
import * as ApiUtils from 'utils/api-utils';

import type {List, Map} from 'immutable';
import type {Demographics} from 'types/demographics-types';
import type {ImmutableMap} from 'types/immutable-types';

type ImmutableMapUnknown = Map<unknown, unknown>;
type ImmutableList = List<unknown>;

export const activateUser = (userId: string): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/activate`,
        method: 'put',
    });
};

export const archiveUser = (userId: string): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/archive`,
        method: 'put',
    });
};

/**
 * Creates the specified users and associates them with the specified organization.
 */
export const bulkImportUsers = (users: object[], organizationId: string): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/users/bulk-import',
        method: 'post',
        data: {
            users,
            organizationId,
        },
    });
};

export const checkEmail = (email: string, userId?: string): Promise<boolean> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/email-exists',
        method: 'get',
        params: {
            email,
            userId,
        },
    });
};

export const checkUserName = (userName: string): Promise<boolean> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/username-exists',
        method: 'get',
        params: {
            userName,
        },
    });
};

export const fetchDemographics = (userId: string): Promise<ImmutableMap<Demographics>> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/demographics`,
        method: 'get',
    });
};

export type CreateDemographicsPayload = Omit<Demographics, 'userId'>;

export type CreateParticipantUserPayload = {
    demographics?: CreateDemographicsPayload;
    email: string;
    firstName: string;
    isSp?: boolean;
    lastName: string;
    middleName?: string;
    number?: string;
    organizationIds: string[];
    password: string;
    ssoExternalId?: string;
    userId: string;
    userName?: string;
};

export type CreateUserWithRolePayload = CreateParticipantUserPayload & {
    userGroupId: string;
};

export const createParticipantUser = (payload: CreateParticipantUserPayload): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/participant',
        method: 'post',
        data: payload,
    });
};

export const createUser = (payload: CreateUserWithRolePayload): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users',
        method: 'post',
        data: payload,
    });
};

export const deleteUser = (userId: string): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}`,
        method: 'delete',
    });
};

/**
 * Log out.
 */
export const logout = (): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user/logout',
        method: 'post',
    });
};

/**
 * Get a user.
 */
export const fetchUser = (userId: string): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}`,
        method: 'get',
    });
};

/**
 * Returns a map of userIds to signed URLs for their avatar images in S3.
 */
export const fetchUserAvatarsSignedUrls = (userIds: string[]): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/avatars/signed-urls`,
        method: 'post',
        data: {
            userIds,
        },
    });
};

/**
 * Do not use this method unless you are sure you need to.
 * Instead, use fetchUsers.
 *
 * @see {@link fetchUsers}
 */
export const fetchUsersRawQuery = (options: object): Promise<ImmutableList & ImmutableMapUnknown> => {
    const req = {
        endpoint: '/users',
        method: 'get',
        params: {
            ...options,
        },
    };
    return ApiUtils.callApiMeteorAuthenticated(req);
};

export const fetchUsers = (options: object): Promise<ImmutableList & ImmutableMapUnknown> => {
    return fetchUsersRawQuery({
        withRoles: true,
        active: true,
        ...options,
    });
};

export const fetchUserGroups = (query: object = {}): Promise<ImmutableList> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/userGroups',
        method: 'get',
        params: query,
    });
};

type ClobberedUser = {
    firstName: string;
    lastName: string;
    userId: string;
    isSp: boolean;
};

/**
 * Fetches active users that are filterable on userGroupTypes, userId, and courseIds
 * If a userId is passed in, a single ClobberedUser is returned.
 */
export const fetchUsersPicklist = async (options: object): Promise<import('immutable').List<ClobberedUser>> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/picklist',
        method: 'get',
        params: {
            ...options,
        },
    });
};

/**
 * Get activity for a user.
 */
export const fetchUserActivity = (
    userId: string,
    query: object = {limit: ACTIVITY_LIMIT},
): Promise<List<ImmutableMapUnknown>> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/activity`,
        method: 'get',
        params: query,
    });
};

/**
 * Get sessions for a user.
 */
export const fetchUserSessions = (userId: string): Promise<List<ImmutableMapUnknown>> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/sessions`,
        method: 'get',
    });
};

/**
 * Get courses for a user with the following course roles: owner, contributor, participant, sp, monitor
 */
export const fetchUserCourses = (userId: string): Promise<List<ImmutableMapUnknown>> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/courses`,
        method: 'get',
    });
};

/**
 * Updates the specified user's password.
 */
export const resetUserPassword = (userId): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/password/reset`,
        method: 'post',
    });
};

/**
 * Searches for users whose names match the specified search value and user group types
 */
export const searchUsers = (searchValue: string, userGroupTypes: string[]): Promise<ImmutableList> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users`,
        method: 'get',
        params: {
            userGroupTypes,
            active: true,
            descending: false,
            order: 'fullName',
            search: searchValue,
        },
    });
};

export type UpdateUserPayload = {
    email?: string;
    firstName?: string;
    isSp?: boolean;
    lastName?: string;
    middleName?: string;
    number?: string;
    organizationIds?: string[];
    ssoExternalId?: string;
    userGroupId?: string;
    userName?: string;
};

export const updateUser = (userId: string, payload: UpdateUserPayload): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}`,
        method: 'patch',
        data: payload,
    });
};

/**
 * Updates the specified user's avatar.
 */
export const updateUserAvatar = (userId: string, payload: object): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        contentType: 'application/octet-stream',
        data: payload,
        endpoint: `/users/${userId}/avatar`,
        method: 'put',
    });
};

export type UpdateUserDemographicsPayload = Omit<Demographics, 'userId'>;

export const updateUserDemographics = (
    userId: string,
    payload: UpdateUserDemographicsPayload,
): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        data: payload,
        endpoint: `/users/${userId}/demographics`,
        method: 'patch',
    });
};

type UpdatePasswordPayload = {
    newPassword: string;
};

/**
 * Updates the specified user's password.
 */
export const updateUserPassword = (userId, payload: UpdatePasswordPayload): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/password`,
        method: 'put',
        data: payload,
    });
};

/**
 * Updates the logged in user's own password.
 */
export const updateProfilePassword = (payload: object): Promise<void> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/profile/password',
        method: 'put',
        data: payload,
    });
};

/**
 * Gets all user preferences for the logged in user.
 */
export const fetchUserPreferences = (): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user-preferences',
        method: 'get',
    });
};

export const updateUserPreferences = (payload: object[]): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/user-preferences',
        method: 'put',
        data: payload,
    });
};

/**
 * Get the global role specified in the query for the user.
 */
export const fetchUserHasGlobalRole = (userId: string, permissionGroups: object): Promise<ImmutableList> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/has-global-role`,
        method: 'get',
        params: permissionGroups,
    });
};

/**
 * Validates the specified users to determine if they can be imported.
 *
 * @returns The result of the validation, including any errors
 */
export const validateBulkImportUsers = (users: object[]): Promise<Immutable.Map<unknown, unknown>> => {
    return ApiUtils.callApiMeteorAuthenticatedUnsafe({
        endpoint: '/users/bulk-import-validate',
        method: 'post',
        data: {
            users,
        },
    });
};

/**
 * Updates the specified user's SP status.
 */
export const updateSpStatus = (userId: string, isSp: boolean): Promise<ImmutableMapUnknown> => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: `/users/${userId}/sp-status`,
        method: 'put',
        data: {
            isSp,
        },
    });
};

/**
 * Archives/Archives the specified users by userId.
 */
export const bulkArchiveDeleteUsers = (
    usersToDelete: Immutable.List<string>,
    usersToArchive: Immutable.List<string>,
) => {
    return ApiUtils.callApiMeteorAuthenticated({
        endpoint: '/users/bulkDeleteOrArchive',
        method: 'POST',
        data: {
            destroy: usersToDelete,
            archive: usersToArchive,
        },
    });
};
