import * as Immutable from 'immutable';
import moment from 'moment-timezone';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import partial from 'lodash/partial';
import set from 'lodash/set';

import ErrorActionTypes from 'constants/error-action-types';
import {createReducer, store} from 'redux-store';
import {sessionIdlingReset, sessionIdlingWarn, userLogout} from '../actions/session-actions';

import ErrorCodes from 'constants/error-codes';
import * as SessionConstants from 'constants/session-constants';
import LocalStorageUtils from 'utils/local-storage-utils';
import MeteorCookies from 'utils/meteor-cookies';

const LAST_ACTIVITY_KEY = 'lastActivity';
import {disconnect} from 'socket-io';

let sessionIdlingTimer;
let sessionIdlingWarningTimer;
let updateIdlingTimerFn;

const NEXT_LOCATION = 'nextLocation';

let lastActivity = null;

if (!isUndefined(LocalStorageUtils.get(LAST_ACTIVITY_KEY))) {
    lastActivity = LocalStorageUtils.get(LAST_ACTIVITY_KEY);
}

const cookies = new MeteorCookies({path: '/'});

import {clearMessaging} from 'components/messaging/messaging-context';
import globalMessages from 'intl/global-messages';
import UIErrorCodeUtils from '../utils/ui-error-code-utils';

const updateUserProfile = (data, userState) => {
    const scopedFields = ['firstName', 'middleName', 'lastName', 'email', 'userName'];

    data = data.filter((value, key) => {
        return scopedFields.indexOf(key) !== -1;
    });

    return userState.mergeDeep(data);
};

const getParameterByName = (name) => {
    if (__UNIT_TEST__) {
        return '';
    }
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.href);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const clearSessionData = (data = {}) => {
    clearMessaging();
    MeteorCookies.wipeDocumentCookies();
    LocalStorageUtils.remove(NEXT_LOCATION);
    disconnect();

    removeIdlingListeners();
    removeIdlingTimer();

    return {
        client: new ClientRecord({
            clientId: null,
            institutionName: null,
        }),
        clusterId: null,
        ...baseState(),
        ...data,
    };
};

export const ClientRecord = Immutable.Record({
    institutionName: cookies.get('institutionName', null),
    clientId: cookies.get('clientId', null),
    settings: {},
});

const baseState = () => {
    return {
        allowPasswordRequest: false,
        allowCredentialsReset: false,
        user: Immutable.Map(),
        linkId: getParameterByName('link'),
        acl: Immutable.fromJS({user: [], client: []}),
        unAuthorizedAction: null,
        roles: Immutable.List(),
        passwordErrorMessage: null,
        status: Immutable.Map(),
        lookups: null,
        getLookupRefById: noop,
        getLookupIdByRef: noop,
    };
};

const initialState = () => {
    return {
        lastActivity: lastActivity,
        nextLocation: LocalStorageUtils.get(NEXT_LOCATION),
        linkId: getParameterByName('link'),
        loginError: null,
        client: new ClientRecord(),
        settings: Immutable.fromJS({
            timezone: 'America/New_York',
            timezoneRules: {},
            hourTwelve: true,
            locale: 'en-US',
            passwordExpirationMinutes: 0,
            sessionTimeoutSeconds: 0,
            timeoutWarningSeconds: 10,
            loginIncorrectAttempts: 0,
        }),
        clusterId: cookies.get('clusterId', null),
        actionError: null,
        userNameExists: false,
        emailExists: false,
        userPreferences: Immutable.Map(),
        lookups: null,
        getLookupRefById: noop,
        getLookupIdByRef: noop,
    };
};

const actionsMap = {
    // Be careful when altering this code. Our intl middleware depends on the
    // locale settings defined here, which it uses to load the correct
    // react-intl locale data and our localization messages for the given
    // locale.
    //
    // The timezoneRules and timezone are used by moment-timezone to format
    // dates for the appropriate timezone. Setting those settings on the moment
    // object is persistent for other instances of moment imported elsewhere.
    // This happens as a side effect of loading updated settings into this
    // store.
    [SessionConstants.SETTINGS_SUCCESS]: (state, action) => {
        const timezone = action.data.get('timezone');
        const timezoneRules = action.data.get('timezoneRules').toJS();

        moment.tz.load(timezoneRules);
        moment.tz.setDefault(timezone);

        const search = new URLSearchParams(window.location.search);
        if (search.has('locale')) {
            action.data = action.data.set('locale', search.get('locale'));
        }

        return {
            settings: action.data, // do not merge, this should be the full data
        };
    },

    [SessionConstants.LOOKUPS_SUCCESS]: (state, action) => {
        const lookups = action.data;
        const getLookupRefById = (tableName, id) => {
            const idKey = `${tableName}Id`;
            return lookups
                .get(tableName, Immutable.Map())
                .find((i) => i.get(idKey) === id, null, Immutable.Map())
                .get('ref');
        };

        const getLookupIdByRef = (tableName, ref) => {
            const idKey = `${tableName}Id`;
            return lookups
                .get(tableName, Immutable.Map())
                .find((i) => i.get('ref') === ref, null, Immutable.Map())
                .get(idKey);
        };

        return {
            getLookupRefById,
            getLookupIdByRef,
            lookups,
        };
    },

    [SessionConstants.DISMISS_UNAUTHORIZED]: (state, action) => {
        return {
            unAuthorizedAction: null,
        };
    },

    [SessionConstants.UNAUTHORIZED_REQUEST]: (state, action) => {
        const errorCodeResponse = get(action, ['data', 'response', 'data']);
        if (isNil(cookies.get('token')) && isNil(errorCodeResponse)) {
            set(action, ['data', 'response', 'data'], ErrorCodes.TOKEN_INVALID);
        } else if (
            UIErrorCodeUtils.errorCodesAreEqual(errorCodeResponse, ErrorCodes.TOKEN_INVALID) ||
            UIErrorCodeUtils.errorCodesAreEqual(errorCodeResponse, ErrorCodes.TOKEN_EXPIRED)
        ) {
            cookies.remove('token');
            cookies.remove('clientId');
        }
        return {
            unAuthorizedAction: action.data,
        };
    },

    [SessionConstants.DISMISS_FROM_RECORDING_START]: (state, action) => {
        return {
            unAuthorizedAction: {
                response: {
                    data: ErrorCodes.UNAUTHORIZED_SESSION_START_USER,
                },
            },
        };
    },
    [ErrorActionTypes.ACTION_ERROR]: (state, action) => {
        if (!isNil(cookies.get('token')) && !state.user.isEmpty()) {
            return {
                actionError: action.data,
            };
        }
        return {
            actionError: null,
        };
    },
    [ErrorActionTypes.DISMISS_ACTION_ERROR]: (state, action) => {
        return {
            actionError: null,
        };
    },
    [SessionConstants.SETTINGS_UPDATE_SUCCESS]: (state, action) => {
        return {
            settings: state.settings.merge(action.data),
        };
    },
    [SessionConstants.UPDATE_NEXT_LOCATION]: (state, action) => {
        if (action.data) {
            LocalStorageUtils.set(NEXT_LOCATION, action.data);
        } else {
            LocalStorageUtils.remove(NEXT_LOCATION);
        }
    },
    [SessionConstants.AUTHENTICATE_USER_SUCCESS]: (state, action) => {
        const {data} = action;

        if (data.clusterId) {
            // We're logging in directly to the cluster
            cookies.set('clusterId', data.clusterId);
            cookies.set('userId', data.userId);
            cookies.set('token', data.token);

            LocalStorageUtils.remove(NEXT_LOCATION);

            return {
                clusterId: data.clusterId,
                loginError: null,
            };
        } else {
            cookies
                .set('clientId', data.clientId)
                .set('institutionName', data.institutionName)
                .set('userId', data.userId)
                .set('token', data.token);

            LocalStorageUtils.remove(NEXT_LOCATION);

            return {
                client: new ClientRecord({
                    clientId: data.clientId,
                    institutionName: data.institutionName,
                }),
                loginError: null,
            };
        }
    },

    [SessionConstants.RESTORE_STORE]: (state, action) => {
        return {
            client: new ClientRecord({
                institutionName: cookies.get('institutionName', null),
                clientId: cookies.get('clientId', null),
            }),
            clusterId: cookies.get('clusterId', null),
            loginError: null,
        };
    },

    [SessionConstants.AUTHENTICATE_USER_FAILURE]: (state, action) => {
        return clearSessionData({
            loginError: action.data,
            lookups: state.lookups,
        });
    },

    [SessionConstants.GET_CLIENT_SSO_LOGIN_URL_FAILURE]: (state, action) => {
        return {
            loginError: action.data,
        };
    },

    [SessionConstants.USER_PROFILE_SUCCESS]: (state, action) => {
        return {
            user: action.data,
        };
    },

    [SessionConstants.USER_PERMISSIONS_SUCCESS]: (state, action) => {
        return {
            acl: action.data,
        };
    },

    [SessionConstants.SET_USER_SESSION_DATA]: (state, action) => {
        return {
            acl: action.data.userPermissions,
            settings: action.data.settings,
            user: action.data.userProfile,
            userPreferences: action.data.userPreferences.get('settings'),
        };
    },

    [SessionConstants.PROFILE_CONTACT_EDIT_SUCCESS]: (state, action) => {
        let data = action.data;

        data = updateUserProfile(data, state.user);

        return {
            user: data,
        };
    },

    [SessionConstants.PROFILE_PASSWORD_EDIT_SUCCESS]: (state, action) => {
        let {status, user} = state;
        user = user.set('passwordValid', true);
        status = status.set('passwordExpired', false);

        return {
            user,
            status,
        };
    },
    [SessionConstants.PROFILE_PASSWORD_EDIT_FAILURE]: (state, action) => {
        let passwordErrorMessage = globalMessages.unknownPasswordError;
        if (!isNil(action.data)) {
            if (action.data.get('statusCode') === 401) {
                passwordErrorMessage = globalMessages.unableToAuthenticate;
            } else {
                switch (action.data.get('errorCode')) {
                    case ErrorCodes.REPEAT_PASSWORD:
                        passwordErrorMessage = globalMessages.oldMatch;
                        break;
                    case ErrorCodes.MISMATCH_PASSWORD:
                        passwordErrorMessage = globalMessages.incorrect;
                        break;
                }
            }
        }
        return {
            passwordErrorMessage,
        };
    },
    [SessionConstants.DISPOSE_SESSION_STORE]: (state, action) => {
        return clearSessionData();
    },
    [SessionConstants.LOGOUT_USER_SUCCESS]: () => {
        return clearSessionData();
    },
    [SessionConstants.LOGOUT_USER_FAILURE]: () => {
        return clearSessionData({
            loginError: ErrorCodes.TOKEN_INVALID,
        });
    },
    [SessionConstants.DISPOSE_PASSWORD_STORE]: () => {
        return {
            passwordErrorMessage: null,
        };
    },
    [SessionConstants.DISPOSE_STORES_FOR_MULTIPLE_USERS]: () => {
        return {
            ...merge(initialState(), baseState()),
            client: new ClientRecord({
                institutionName: cookies.get('institutionName', null),
                clientId: cookies.get('clientId', null),
            }),
        };
    },

    [SessionConstants.PROFILE_CHECK_USERNAME_SUCCESS]: (state, action) => {
        return {
            userNameExists: action.data,
        };
    },

    [SessionConstants.PROFILE_CHECK_EMAIL_SUCCESS]: (state, action) => {
        return {
            emailExists: action.data,
        };
    },

    [SessionConstants.FETCH_USER_PREFERENCES_SUCCESS]: (state, action) => {
        return {
            userPreferences: action.data.get('settings'),
        };
    },

    [SessionConstants.UPDATE_USER_PREFERENCES_SUCCESS]: (state, action) => {
        let userPreferences = action.data.get('settings');

        if (Immutable.Map.isMap(userPreferences)) {
            userPreferences = userPreferences.toJS();
        } else {
            userPreferences = JSON.parse(userPreferences);
        }

        return {
            userPreferences: Immutable.fromJS(userPreferences),
        };
    },

    [SessionConstants.DISMISS_STATUS_WARNING]: (state, action) => {
        return {
            status: state.status.set(action.statusType, false),
        };
    },

    // todo: these don't need to be actions on the store
    // todo: replace doesn't need to be passed on the action
    [SessionConstants.SESSION_IDLING_INIT]: (state, action) => {
        const {settings} = state;
        const {replace} = action;

        // set timer for when user will be logged out
        const sessionTimeout = settings.get('sessionTimeoutSeconds') * 1000;
        const timeoutWarning = sessionTimeout - settings.get('timeoutWarningSeconds') * 1000;

        if (!isNil(sessionTimeout) && sessionTimeout > 0) {
            setIdlingListeners(replace, sessionTimeout, timeoutWarning);
            setIdlingTimer(replace, sessionTimeout, timeoutWarning);
        }

        return {};
    },

    [SessionConstants.SESSION_IDLING_DEACTIVATE]: (state) => {
        removeIdlingTimer();
        removeIdlingListeners();
    },

    [SessionConstants.SESSION_IDLING_RESET]: (state) => {
        const {status} = state;

        return {
            status: !isNil(status.get('timeout')) ? status.delete('timeout') : status,
        };
    },

    [SessionConstants.SESSION_IDLING_WARN]: (state, action) => {
        return {
            status: state.status.set('timeout', true),
        };
    },
};

export default createReducer(merge(initialState(), baseState()), actionsMap);

const setIdlingListeners = (replace, sessionTimeout, timeoutWarning) => {
    removeIdlingListeners();
    updateIdlingTimerFn = partial(updateIdlingTimer, replace, sessionTimeout, timeoutWarning);
    SessionConstants.SESSION_IDLING_EVENTS.forEach((value) => {
        window.addEventListener(value, updateIdlingTimerFn);
    });
};

const removeIdlingListeners = () => {
    if (!isNil(updateIdlingTimerFn)) {
        SessionConstants.SESSION_IDLING_EVENTS.forEach((value) => {
            window.removeEventListener(value, updateIdlingTimerFn);
        });
    }
    updateIdlingTimerFn = null;
};

const setIdlingTimer = (replace, sessionTimeout, timeoutWarning) => {
    // todo: don't pass replace
    removeIdlingTimer();
    sessionIdlingTimer = window.setTimeout(() => {
        sessionIdlingTimer = null;
        // TODO: MET-4363 TOKEN_EXPIRED error can occur before sessionTimeout which leads to double warnings
        const successFn = partial(replace, '/login');
        store.dispatch(userLogout(successFn, successFn));
    }, sessionTimeout);

    if (timeoutWarning > 0) {
        sessionIdlingWarningTimer = window.setTimeout(() => {
            sessionIdlingWarningTimer = null;
            if (!isNil(sessionIdlingTimer)) {
                store.dispatch(sessionIdlingWarn());
            }
        }, timeoutWarning);
    }
};

const removeIdlingTimer = () => {
    if (!isNil(sessionIdlingTimer)) {
        window.clearTimeout(sessionIdlingTimer);
        sessionIdlingTimer = null;
    }
    if (!isNil(sessionIdlingWarningTimer)) {
        window.clearTimeout(sessionIdlingWarningTimer);
        sessionIdlingWarningTimer = null;
    }
};

/**
 * When the user performs a keyboard / touch / mouse activity, set the idling
 * timer to expire sessionTimeout seconds from now. Since activity happens in
 * bursts, we debounce.
 */
const updateIdlingTimer = debounce(
    (replace, sessionTimeout, timeoutWarning) => {
        if (!isNil(sessionIdlingTimer)) {
            setIdlingTimer(replace, sessionTimeout, timeoutWarning);
        }
        return store.dispatch(sessionIdlingReset());
    },
    1000,
    {leading: true, trailing: true},
);
